import React from 'react';
import RocketImageSource from './images/rocket.svg';
import PowerImageSource from './images/power.svg';
import ChalkboardImageSource from './images/chalkboard.svg';
import CommunityImageSource from './images/community.svg';
import { TwoColumnList } from '../../shared/TwoColumnList';

const items = [
  { image: RocketImageSource, title: 'Faster than any courier', description: 'Citizens are commuting around the city non stop. That means we have someone ready to delivery your parcel nearby, right now.' },
  { image: PowerImageSource, title: 'Better for the environment', description: 'With no unnecessary trips, it means no trucks and no depots. Your parcels are going direct, utilising otherwise wasted energy.' },
  { image: ChalkboardImageSource, title: 'Easier and more efficient', description: 'No more waiting in line or for couriers to pick up your parcels. Simply post your job with our app and we’ll take care of the rest.' },
  { image: CommunityImageSource, title: 'Giving back to the community', description: '70% of our profits go back to the community for delivering the parcels. Help us strengthen the economy and be part of something great.' },
];

const ServicesList = () => (
  <TwoColumnList items={items} />
);

export { ServicesList };