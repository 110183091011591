import React from 'react';
import styled from 'styled-components';

import { Layout } from '../components/shared/layout';
import { Page } from '../components/shared/Page';
import { ServicesBanner } from '../components/modules/ServicesPage';
import { CommuterNavigation } from '../components/modules/common/CommuterNavigation/CommuterNavigation';
import { DownloadMerchantsApp } from '../components/modules/common/DownloadHandCarryApp';
import { ServicesList } from '../components/modules/ServicesPage/ServicesList';

const StyledServicesPage = styled(Layout)`
  text-align: center;
`;

const ServicesPage = () => (
  <StyledServicesPage headerTransparent>
    <Page>
      <ServicesBanner />
      <ServicesList />
      <CommuterNavigation />
      <DownloadMerchantsApp />
    </Page>
  </StyledServicesPage>
);

export default ServicesPage;
