import React from 'react';
import styled from 'styled-components';
import {
  Banner, BannerContent, BannerDescription, BannerContentItem, BannerTitle, WatchVideoBannerButton,
} from '../../shared/Banner'
import { PageImage } from '../../shared/PageImage';
import ServicesBannerIconSource from './images/servicesBannerIcon.png';
import { STYLES } from '../../../style';

const StyledServicesBanner = styled(Banner)``;

const BannerImage = styled(PageImage)`
  max-height: 319px;
`;

const ServicesBanner = () => (
  <StyledServicesBanner color={STYLES.PAGE_SERVICES_BANNER_COLOR}>
    <BannerContent>
      <BannerContentItem>
        <BannerImage src={ServicesBannerIconSource} />
      </BannerContentItem>
      <BannerContentItem>
        <BannerTitle>HOW IS HAND CARRY BETTER?</BannerTitle>
        <BannerDescription>
          At it’s core, Hand Carry provides inner city
          parcel deliveries for businesses that are looking
          for the edge in speed, price and efficiency.
        </BannerDescription>
        <WatchVideoBannerButton />
      </BannerContentItem>
    </BannerContent>
  </StyledServicesBanner>
);

export { ServicesBanner };